/* eslint-disable react/require-default-props */
import React, { FC, useState, ChangeEvent } from 'react';
import Select from 'react-dropdown-select';
import { SearchOutlined } from '@ant-design/icons';
import './checkboxDropdown.scss';
import Checkbox from '../Checkbox';
import type { DropdownOption } from './types';

interface DropdownProps {
    options: DropdownOption[];
    onSelect: (values: DropdownOption[]) => void;
    values: DropdownOption[];
    endLabel?: string;
    handleEndLabel?: (item: DropdownOption) => void;
}

const customStyles = {
    marginTop: '0px',
    minHeight: '40px',
    minWidth: '300px',
    borderRadius: '10px',
    padding: '10px',
    border: '0.5x solid #20639B',
};

const searchInputStyles = {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #20639B',
    paddingLeft: '35px',
};

const placeholderStyles = `
    .search-input::placeholder {
        color: #20639B;
        font-weight: 500;
    }
`;

const Dropdown: FC<DropdownProps> = ({ options, onSelect, values, endLabel, handleEndLabel }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const selectAll = () => onSelect(options);
    const clearAll = () => onSelect([]);

    const handleOnChange = (newValues: DropdownOption[]) => {
        onSelect(newValues);
    };

    const getContentRenderer = () => (
        <div className="custom-content-renderer">
            <div style={{ color: '#20639B' }}>
                {values && values.length > 0 ? `${values.length} Care Homes Selected` : 'All Care Homes'}
            </div>
        </div>
    );

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredOptions = options.filter(option => option.label.toLowerCase().includes(searchTerm));

    const getItem = (item: DropdownOption, state: { values: DropdownOption[] }) => {
        const included = state.values.some(value => value.value === item.value);
        const toggleItem = () => {
            if (included) {
                onSelect(state.values.filter(value => value.value !== item.value));
            } else {
                onSelect([...state.values, item]);
            }
        };

        if ('children' in item) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 20px 0px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                        <style>{placeholderStyles}</style>
                        <SearchOutlined style={{ position: 'absolute', top: '33px', left: '30px', color: '#20639B' }} />
                        <input
                            className="search-input"
                            type="text"
                            placeholder="Search Care Homes"
                            style={searchInputStyles}
                            onChange={handleSearchChange} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '20px' }}>
                        {item.children.map((child: DropdownOption) => (
                            <div onClick={child.action || undefined} key={child.value} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#20639B' }}>
                                {child.label}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return (
            <div className="item-wrapper">
                <Checkbox checked={included} onChange={toggleItem} />
                <div className="labels-wrapper">
                    <span className="item-label">{item.label}</span>
                    {endLabel && (
                        <span className="end-label" onClick={() => handleEndLabel && handleEndLabel(item)}>
                            {endLabel}
                        </span>
                    )}
                </div>
            </div>
        );
    };

    const extendedOptions = [
        {
            label: 'Actions',
            value: 'actions',
            specialOption: true,
            action: null,
            children: [
                { label: 'Select All', value: 'selectAll', action: selectAll },
                { label: 'Clear', value: 'clearAll', action: clearAll },
            ],
        },
        ...filteredOptions,
    ];

    return (
        <div className="container">
            <Select
                searchable
                contentRenderer={getContentRenderer}
                style={customStyles}
                placeholder="All Care Homes"
                options={extendedOptions}
                values={values}
                onChange={handleOnChange}
                multi
                color="#20639B"
                itemRenderer={({ item, state }) => getItem(item, state)} />
        </div>
    );
};

export default Dropdown;
