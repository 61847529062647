/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'antd';
import { fetchAssessmentBreakdown } from 'core/assessment/assessmentApi';
import { AssessmentBreakdownItem, GraphDataItem } from 'core/assessment/types';
import { Roles } from 'core/constants';
import { CombinedStates, AppDispatch } from '../../core/types';
import GraphComponent from '../GraphComponent';
import TableComponent from '../TableComponent';
import './breakdown.scss';

const Breakdown: React.FC<BreakdownProps> = ({ role, careHomeId, isExporting }) => {
    const [view, setView] = useState('table');
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (role === Roles.ZONE_MANAGER && careHomeId) {
            dispatch(fetchAssessmentBreakdown({ careHomeId: [careHomeId] }));
        } else {
            dispatch(fetchAssessmentBreakdown({}));
        }
    }, [dispatch, role, careHomeId]);

    const breakdownData = useSelector((state: CombinedStates) => state.assessment.breakdownData) as AssessmentBreakdownItem[];
    const toggleView = () => {
        setView(view === 'table' ? 'graph' : 'table');
    };

    const graphData: GraphDataItem[] = (breakdownData || []).map(item => ({
        careHome: item?.careHome,
        completed: Number(item?.completed),
        reassessments: Number(item?.reassessments),
        abandoned: Number(item?.abandoned),
        title: item?.title,
    }));

    const sortedData = breakdownData
        ? [...breakdownData].sort((a, b) => (a.title ?? '').localeCompare(b.title ?? ''))
        : [];

    return (
        <div className="parent">
            <h3 className="title">Users ISTUMBLE Processes Breakdown</h3>
            <div className="breakdown-container">
                <div className="button-container">
                    <p className="toggle-title">Toggle List & Graph View</p>
                    <Switch
                        defaultChecked={view === 'graph'}
                        onChange={toggleView} />
                </div>
                <div className="component-container">
                    {view === 'table' ? (
                        <TableComponent data={sortedData} isExporting={isExporting} />
                    ) : (
                        <GraphComponent data={graphData} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Breakdown;
