/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Row, Col, Button, Input, Dropdown as AntDropdown, MenuProps, message } from 'antd';
import { CalendarOutlined, DownloadOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { DownIcon } from 'components/Elements/CustomSVGIcon';
import { Calendar, DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import Radio from 'components/Radio';
import Dropdown from 'components/CheckboxDropdown';
import { Roles } from 'core/constants';
import { fetchCareHomes } from 'core/assessment/assessmentApi';
import { setExporting } from 'core/account/accountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedStates, AppDispatch } from 'core/types';
import { AssessmentData, AssessmentBreakdownItem, AssessmentCallOut } from 'core/assessment/types';
import SelectedCareHomes from 'components/SelectedCareHomes';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { debounce } from 'underscore';
import './dashboardHeader.scss';

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ role, careHomeId, onApply }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { careHomes, data: overviewData, breakdownData, callOuts } = useSelector((state: CombinedStates) => state.assessment);
    const { isExporting } = useSelector((state: CombinedStates) => state.account);
    const [selectedCareHomes, setSelectedCareHomes] = useState<CareHomeOption[]>([]);
    const [selectedDates, setSelectedDates] = useState<Date[]>([new Date(), new Date()]);
    const [startTime, setStartTime] = useState<string>('09:00');
    const [endTime, setEndTime] = useState<string>('17:00');
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
    const [dateRangeOption, setDateRangeOption] = useState<string>('today');
    const [isDownloadDropdownVisible, setIsDownloadDropdownVisible] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (role !== Roles.ZONE_MANAGER) {
            dispatch(fetchCareHomes());
        }
    }, [dispatch, role]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownVisible(false);
            }
        };

        if (isDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownVisible]);

    const careHomeOptions: CareHomeOption[] = (careHomes ?? []).map(careHome => ({
        label: careHome.title,
        value: careHome.id.toString(),
    }));

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleDateShortcut = (value: string) => {
        if (value === 'week') {
            setSelectedDates([moment().startOf('week').toDate(), moment().endOf('week').toDate()]);
        } else if (value === 'today') {
            const today = new Date();
            setSelectedDates([today, today]);
        } else if (value === 'month') {
            setSelectedDates([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
        } else if (value === 'custom') {
            setSelectedDates([]);
        }
        setDateRangeOption(value);
    };

    const handleRadioChange = (value: string) => {
        handleDateShortcut(value);
    };

    const getDateRangeLabel = () => {
        if (dateRangeOption === 'custom' && selectedDates.length === 0) {
            return 'Custom';
        }
        const start = moment(selectedDates[0]).startOf('day');
        const end = moment(selectedDates[1]).endOf('day');

        const isToday = start.isSame(moment().startOf('day')) && end.isSame(moment().endOf('day'));
        const isThisWeek = start.isSame(moment().startOf('week')) && end.isSame(moment().endOf('week'));
        const isThisMonth = start.isSame(moment().startOf('month')) && end.isSame(moment().endOf('month'));

        if (isToday) return 'Today';
        if (isThisWeek) return 'This Week';
        if (isThisMonth) return 'This Month';

        // Set the option as custom but display the date range
        return `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}`;
    };

    const handleCustomDateSelection = (dates: DateObject[]) => {
        setSelectedDates(dates.map(date => date.toDate()));
        setDateRangeOption('custom');
    };

    const options = [
        { label: 'Today', value: 'today' },
        { label: 'This Week', value: 'week' },
        { label: 'This Month', value: 'month' },
        { label: 'Custom', value: 'custom' },
    ];

    const applySelection = useCallback((careHomeSelection: CareHomeOption[]) => {
        const formattedStartDate = moment(selectedDates[0]).format('YYYY-MM-DD');
        const formattedEndDate = moment(selectedDates[1]).format('YYYY-MM-DD');
        const selectedCareHomeIds = careHomeSelection.map(ch => parseInt(ch.value, 10));

        const careHomeIds = role === Roles.ZONE_MANAGER ? [careHomeId] : selectedCareHomeIds;
        const formattedStartTime = `${startTime}:00`;
        const formattedEndTime = `${endTime}:00`;

        const timeRange = dateRangeOption === 'custom'
            ? `${formattedStartDate} to ${formattedEndDate}`
            : dateRangeOption;

        onApply(formattedStartDate, formattedEndDate, careHomeIds, formattedStartTime, formattedEndTime, timeRange);
    }, [selectedDates, startTime, endTime, dateRangeOption, role, careHomeId, onApply]);

    const debouncedApplySelection = useMemo(() => debounce((careHomeSelection: CareHomeOption[]) => {
        applySelection(careHomeSelection);
    }, 1000), [applySelection]);

    useEffect(() => () => {
        debouncedApplySelection.cancel();
    }, [debouncedApplySelection]);

    const handleCareHomeSelect = (values: CareHomeOption[]) => {
        setSelectedCareHomes(values);
        debouncedApplySelection(values);
    };

    const handleRemoveCareHome = (careHomeToRemove: CareHomeOption) => {
        const updatedCareHomes = selectedCareHomes.filter(careHome => careHome.value !== careHomeToRemove.value);
        setSelectedCareHomes(updatedCareHomes);
        debouncedApplySelection(updatedCareHomes);
    };

    const handleApplyClick = () => {
        if (dateRangeOption === 'custom' && selectedDates.length === 0) {
            message.error('Please select a date range');
            return;
        }
        applySelection(selectedCareHomes);
        setIsDropdownVisible(false);
    };

    const toggleDropdownVisibility = () => {
        setIsDownloadDropdownVisible(!isDownloadDropdownVisible);
    };

    const generateXlsxData = async (
        fetchedOverviewData: AssessmentData,
        fetchedBreakdownData: AssessmentBreakdownItem[],
        fetchedCallOuts: AssessmentCallOut[],
        selectedFilters: { careHome: string; dateRange: string; timeFrame: string; },
    ) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Dashboard Report');

        worksheet.columns = [
            { width: 30 }, // A
            { width: 25 }, // B
            { width: 25 }, // C
            { width: 25 }, // D
            { width: 25 }, // E
            { width: 30 }, // F
            { width: 30 }, // G
        ];

        worksheet.mergeCells('A1:G1');
        const logoCell = worksheet.getCell('A1');
        logoCell.value = 'iStumble';
        logoCell.font = { size: 20, bold: true, color: { argb: 'FF0079C1' } };
        logoCell.alignment = { horizontal: 'center', vertical: 'middle' };

        worksheet.mergeCells('A2:G2');
        const titleCell = worksheet.getCell('A2');
        titleCell.value = 'iStumble Report';
        titleCell.font = { size: 16, bold: true };
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };

        // Add filters row
        worksheet.addRow(['Care Home Filter', 'Date Range', 'Time Frame']);
        worksheet.addRow([selectedFilters.careHome, selectedFilters.dateRange, selectedFilters.timeFrame]);

        worksheet.addRow([]); // Add empty row

        // Overview headers with bold styling
        const overviewHeaders = [
            'ISTUMBLE Fall Guides', 'Patient Falls', 'Emergency Service Calls',
            'Hospital Transfers', 'Patients Lifted Safely', 'Patients Injured', 'Number of Re-Assessments',
        ];
        const overviewHeaderRow = worksheet.addRow(overviewHeaders);
        overviewHeaderRow.font = { bold: true };

        // Add overview data
        worksheet.addRow([
            fetchedOverviewData['iStumble Fall Guides'] || 0,
            fetchedOverviewData['Patient Falls'] || 0,
            fetchedOverviewData['Emergency Service Calls'] || 0,
            fetchedOverviewData['Hospital Transfers'] || 0,
            fetchedOverviewData['Patients Lifted Safely'] || 0,
            fetchedOverviewData['Patients Injured'] || 0,
            fetchedOverviewData['Number of Re-Assessments'] || 0,
        ]);

        worksheet.addRow([]);

        const breakdownHeaders = ['Care Home', 'Total Processes', 'Completed Processes', 'Re-Assessments', 'Abandoned Processes'];
        const breakdownHeaderRow = worksheet.addRow(breakdownHeaders);
        breakdownHeaderRow.font = { bold: true };

        fetchedBreakdownData.forEach((item) => {
            worksheet.addRow([
                item.title,
                item.total || 0,
                item.completed || 0,
                item.reassessments || 0,
                item.abandoned || 0,
            ]);
        });

        worksheet.addRow([]);

        const calloutHeaders = ['Day', 'Falls', 'Ambulance Call Outs'];
        const calloutHeaderRow = worksheet.addRow(calloutHeaders);
        calloutHeaderRow.font = { bold: true };

        fetchedCallOuts.forEach((item) => {
            worksheet.addRow([
                item.date,
                item.falls || 0,
                item.ambulanceCallOuts || 0,
            ]);
        });

        worksheet.addRow([]);

        const titleRow = worksheet.addRow(['Average Ambulance Call Outs', 'Money Saved by ISTUMBLE']);
        titleRow.font = { bold: true };
        worksheet.addRow([
            fetchedCallOuts.length > 0 ? (fetchedCallOuts.reduce((sum, item) => sum + item.ambulanceCallOuts, 0) / fetchedCallOuts.length).toFixed(1) : 0,
            `£${(((fetchedOverviewData?.['iStumble Fall Guides'] ?? 0) - (fetchedOverviewData?.['Emergency Service Calls'] ?? 0)) * 350).toLocaleString()}`,
        ]);
        worksheet.addConditionalFormatting({
            ref: 'A1:G5',
            rules: [
                {
                    type: 'expression',
                    priority: 1,
                    formulae: ['MOD(ROW()+COLUMN(),2)=0'],
                    style: {
                        fill: {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFFFFF' },
                        },
                    },
                },
            ],
        });
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'dashboard_report.xlsx');
    };

    const handleResetClick = () => {
        setSelectedCareHomes([]);
        setSelectedDates([new Date(), new Date()]);
        setStartTime('09:00');
        setEndTime('17:00');
        setDateRangeOption('today');
    };

    const handleMenuClick = async (e: { key: string; }) => {
        const selectedFilters = {
            careHome: selectedCareHomes.length ? selectedCareHomes.map(ch => ch.label).join(', ') : 'All Care Homes',
            dateRange: `${moment(selectedDates[0]).format('MMM D, YYYY')} - ${moment(selectedDates[1]).format('MMM D, YYYY')}`,
            timeFrame: `${startTime} - ${endTime}`,
        };

        if (e.key === 'csv') {
            if (overviewData && breakdownData && callOuts) {
                await generateXlsxData(overviewData, breakdownData, callOuts, selectedFilters);
                message.success('XLSX report generated successfully');
            } else {
                message.error('Data is missing');
            }
        } else if (e.key === 'pdf') {
            const content = document.getElementById('dashboard-content');
            const callout = document.getElementById('callout-section');

            if (content && callout) {
                content.classList.add('pdf-export-mode');

                dispatch(setExporting(true));

                setTimeout(() => {
                    const pdf = new jsPDF('p', 'mm', 'a4');
                    let position = 10;

                    html2canvas(content, { scale: 2 }).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const imgWidth = 190;
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;

                        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                        position += imgHeight + 10;

                        pdf.addPage();
                        return html2canvas(callout, { scale: 2 });
                    }).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const imgWidth = 190;
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;

                        pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

                        pdf.save('dashboard_report.pdf');
                        dispatch(setExporting(false));
                        message.success('PDF generated successfully');

                        content.classList.remove('pdf-export-mode');
                    }).catch((error) => {
                        dispatch(setExporting(false));
                        message.error('Error generating PDF');
                        console.error('Error generating PDF:', error);

                        content.classList.remove('pdf-export-mode');
                    });
                }, 500);
            } else {
                console.error('One of the elements not found');
            }
        }
    };

    const menuItems: MenuProps['items'] = [
        {
            key: 'csv',
            label: 'CSV Export',
        },
        {
            key: 'pdf',
            label: 'PDF Export',
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle" className="dashboard-header">
                <Col flex="auto" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div className="dropdown-container">
                        <Button onClick={toggleDropdown} className="button">
                            {getDateRangeLabel()} <CalendarOutlined style={{ marginLeft: '100px' }} />
                        </Button>
                        {isDropdownVisible && (
                            <div ref={dropdownRef} className="custom-dropdown">
                                <Row gutter={[16, 16]}>
                                    {/* Radio Group Column */}
                                    <Col span={6}>
                                        <div className="radio-group">
                                            {options.map(option => (
                                                <Radio
                                                    key={option.value}
                                                    value={option.value}
                                                    checked={dateRangeOption === option.value}
                                                    onChange={handleRadioChange}
                                                    label={option.label} />
                                            ))}
                                        </div>
                                    </Col>

                                    {/* Date Picker Column */}
                                    <Col span={10} className="date-container">
                                        <h3 className="date-title">Date</h3>
                                        <Calendar
                                            key={dateRangeOption}
                                            value={dateRangeOption === 'custom' && selectedDates.length === 0
                                                ? []
                                                : selectedDates as unknown as DateObject[]}
                                            onChange={handleCustomDateSelection}
                                            range
                                            maxDate={moment().add(6, 'months').toDate()}
                                            minDate={moment().subtract(365, 'days').toDate()}
                                            className={`date-picker ${dateRangeOption === 'custom' ? 'custom-mode' : ''}`} />
                                    </Col>

                                    <Col span={8}>
                                        <div className="time-input-container">
                                            <h3 className="time-title">Time Frame</h3>
                                            <label className="label">Start Time</label>
                                            <div className="custom-time-input">
                                                <Input type="time" value={startTime} onChange={e => setStartTime(e.target.value)} className="time-input" />
                                                <span className="down-arrow-icon"><DownIcon /></span>
                                            </div>
                                            <label className="label">End Time</label>
                                            <div className="custom-time-input">
                                                <Input type="time" value={endTime} onChange={e => setEndTime(e.target.value)} className="time-input" />
                                                <span className="down-arrow-icon"><DownIcon /></span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row justify="center" className="containerOne">
                                    <Col span={24} style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <Button type="primary" className="applyButton" onClick={handleApplyClick}>Apply</Button>
                                        <Button className="resetButton" onClick={handleResetClick}>Reset</Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                    {role !== Roles.ZONE_MANAGER && (
                        <Dropdown
                            options={careHomeOptions}
                            onSelect={handleCareHomeSelect}
                            values={selectedCareHomes} />
                    )}
                    {!isExporting && (
                        <AntDropdown menu={{ items: menuItems, onClick: handleMenuClick }} open={isDownloadDropdownVisible} onOpenChange={setIsDownloadDropdownVisible} trigger={['click']} placement="bottomRight">
                            <Button className="download-button" onClick={toggleDropdownVisibility} icon={<DownloadOutlined />}>
                                <span>Download Report</span>
                                <span className="caret-container">
                                    {isDownloadDropdownVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                                </span>
                            </Button>
                        </AntDropdown>
                    )}
                </Col>
            </Row>
            {role !== Roles.ZONE_MANAGER && (
                <SelectedCareHomes selectedCareHomes={selectedCareHomes} onRemove={handleRemoveCareHome} />
            )}
        </>
    );
};

export default DashboardHeader;
