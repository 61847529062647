import React, { FC } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../../core/user/userApi';
import { ForgotPasswordFormProps, valuesProps, FinishedFailedProps } from './types';
import './forgotPasswordForm.scss';
import { AppDispatch } from '../../../core/types';

const ForgotPasswordForm:FC<ForgotPasswordFormProps> = ({ backLink }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [form] = Form.useForm();

    const onFinish = (values: valuesProps) => {
        try {
            dispatch(forgotPassword({ email: values.email }));
            notification.success({
                message: 'Check Your Email',
                description: 'If the email is associated with an account, we have sent a password reset link to it.',
            });
        } catch (error) {
            console.error('Failed to reset password:', error);
        }
    };

    const onFinishFailed = ({ errorFields }:FinishedFailedProps) => {
        form.scrollToField(errorFields[0]?.name);
    };

    return (
        <div className="login-form-box">
            <div className="back-link">
                <Button onClick={() => { window.location.href = backLink.link; }} type="link" icon={<ArrowLeftOutlined />} style={{ marginTop: '10px' }}>
                    {backLink.title || 'Back'}
                </Button>
            </div>
            <div className="form">
                <h3 className="form-title">Forgotten Password</h3>
                <p className="sub-title">Please enter the email address below to reset your password</p>
                <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    autoComplete="off">
                    <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[{ type: 'email', message: 'The input is not a valid email!' }, { required: true, message: 'Please input your email.' }]}>
                        <Input placeholder="Enter your email" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block className="login-btn">
                            Reset Password
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;
