/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Dropdown, MenuProps, Modal } from 'antd';
import { IconEdit } from 'components/Elements/CustomSVGIcon';
import AddCareHomeUserModal from 'components/AddCareHomeUserModal';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Roles } from 'core/constants';
import { getUsers, createUser, deleteUser, updateUser } from 'core/account/accountApi';
import Spinner from 'components/Spinner';
import { formatDate } from 'core/utils/helpers';
import { fetchLocalAuthorities } from 'core/assessment/assessmentApi';
import Main from '../../components/layout/Main';
import { CreateUserPayload } from '../../core/account/types';
import { CombinedStates, AppDispatch } from '../../core/types';
import './myAccount.scss';

const MyAccount: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { userData } = useSelector((state: CombinedStates) => state.user);
    const { users, isFetching, success } = useSelector((state: CombinedStates) => state.account);
    const { localAuthorities } = useSelector((state: CombinedStates) => state.assessment);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState<CareHomeUser | null>(null);
    const [userToDelete, setUserToDelete] = useState<CareHomeUser | null>(null);

    useEffect(() => {
        dispatch(getUsers());
        dispatch(fetchLocalAuthorities());
    }, [dispatch]);

    const handleMenuClick = (e: MenuInfo, record: CareHomeUser) => {
        if (e.key === 'view') {
            setSelectedUser(record);
            setIsModalVisible(true);
        } else if (e.key === 'delete') {
            setUserToDelete(record);
            setIsDeleteModalVisible(true);
        }
    };

    const getMenuItems = (record: CareHomeUser): MenuProps['items'] => {
        const isZoneAdmin = userData?.role?.name === Roles.ZONE_ADMIN;
        const isZoneManager = userData?.role?.name === Roles.ZONE_MANAGER;

        return [
            {
                key: 'view',
                label: 'View',
                onClick: () => handleMenuClick({ key: 'view' } as MenuInfo, record),
            },
            ...(isZoneAdmin && record?.role?.name !== Roles.ZONE_ADMIN ? [{
                key: 'delete',
                label: 'Delete',
                onClick: () => handleMenuClick({ key: 'delete' } as MenuInfo, record),
            }] : []),
            ...(isZoneManager && record?.role?.name !== Roles.ZONE_ADMIN ? [{
                key: 'delete',
                label: 'Delete',
                onClick: () => handleMenuClick({ key: 'delete' } as MenuInfo, record),
            }] : []),
        ];
    };

    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Email Address', dataIndex: 'email', key: 'email' },
        ...(userData?.role?.name !== Roles.ZONE_MANAGER
            ? [{ title: 'Care Home', dataIndex: ['care_home', 'title'], key: 'care_home.title' }]
            : []),
        { title: 'Job Title', dataIndex: 'job_title', key: 'job_title' },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: CareHomeUser) => (
                <Dropdown menu={{ items: getMenuItems(record) || [] }} trigger={['click']} placement="bottomRight" className="custom-dropdown">
                    <Button type="link" icon={<IconEdit />} />
                </Dropdown>
            ),
        },
    ];

    const showModal = () => {
        setSelectedUser(null);
        setIsModalVisible(true);
    };

    const handleSave = async (values: FormData) => {
        try {
            const payload: CreateUserPayload = {
                name: values.name,
                email: values.email,
                jobTitle: values.jobTitle,
                role: values.role,
                care_home_id: values.care_home_id,
                local_authority_id: values.local_authority_id,
            };

            if (selectedUser) {
                await dispatch(updateUser({ ...payload, id: selectedUser.id }));
            } else {
                await dispatch(createUser(payload));
            }

            await dispatch(getUsers());
            setIsModalVisible(false);
        } catch (error) {
            console.error('Save user error:', error);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDelete = async () => {
        try {
            if (userToDelete && userToDelete.id) {
                await dispatch(deleteUser(userToDelete.id));
                await dispatch(getUsers());
                setIsDeleteModalVisible(false);
                setUserToDelete(null);
            } else {
                console.error('No user ID found to delete');
            }
        } catch (error) {
            console.error('Delete user error:', error);
        }
    };

    return (
        <Main
            className="my-account"
            title={<div>My Account</div>}
            headerTitle={<div>My Account</div>}
            floatingHeader={false}
            footerContent={<div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2024</div>}
            backLink={<Button type="link">Back</Button>}
            menuPlacement="left"
            menuWidth={0}>
            {isFetching ? (
                <Spinner />
            ) : (
                <div>
                    <h3 className="header">Account Details</h3>
                    <div className="account-details">
                        <div className="detail-item">
                            <p className="title-header">Name:</p>
                            <p>{userData?.first_name} {userData?.last_name || ''}</p>
                        </div>
                        <div className="detail-item">
                            <p className="title-header">Email Address:</p>
                            <p>{userData?.email}</p>
                        </div>
                        {userData?.role?.name === Roles.ZONE_MANAGER ? (
                            <div className="detail-item">
                                <p className="title-header">Care Home Name:</p>
                                <p>{userData?.care_home_title || 'N/A'}</p>
                            </div>
                        ) : (
                            <>
                                <div className="detail-item">
                                    <p className="title-header">Zone Name:</p>
                                    <p>{userData?.zone?.title || 'N/A'}</p>
                                </div>
                                <div className="detail-item">
                                    <p className="title-header">Subscription End Date:</p>
                                    <p>{userData?.zone?.expiry_date ? formatDate(userData.zone.expiry_date) : 'N/A'}</p>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="table-header">
                        <h3 className="table-title">Care Home Users</h3>
                        <Button type="primary" style={{ fontSize: '14px' }} onClick={showModal}>
                            ┼ Add a Care Home User
                        </Button>
                    </div>
                    <Table
                        dataSource={users}
                        columns={columns}
                        pagination={{ pageSize: 10 }} />
                </div>
            )}
            <AddCareHomeUserModal
                visible={isModalVisible}
                onClose={handleCancel}
                onSave={handleSave}
                localAuthorities={localAuthorities}
                isFetching={isFetching}
                isZoneManager={userData?.role?.name === Roles.ZONE_MANAGER}
                user={selectedUser}
                success={success} />

            {/* Delete Confirmation Modal */}
            <Modal
                title="Delete User"
                open={isDeleteModalVisible}
                footer={[
                    <Button key="delete" type="primary" danger onClick={handleDelete} style={{ width: '230px', borderRadius: '11px', height: '45px', backgroundColor: '#0079C1' }}>
                        Delete
                    </Button>,
                    <Button key="cancel" onClick={() => setIsDeleteModalVisible(false)} style={{ width: '230px', borderRadius: '11px', height: '45px', borderColor: '#0079C1', color: '#0079C1' }}>
                        Cancel
                    </Button>,
                ]}
                onCancel={() => setIsDeleteModalVisible(false)}>
                <p>
                    Deleting <strong>{userToDelete?.name}</strong> will permanently delete the record of this user on the system. Are you sure you want to delete this user?
                </p>
            </Modal>
        </Main>
    );
};
export default MyAccount;
