/* eslint-disable import/namespace */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button, AutoComplete, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCareHomes } from 'core/assessment/assessmentApi';
import { createCareHome } from 'core/account/accountApi';
import { CombinedStates, AppDispatch } from 'core/types';
import './addCareHomeUserModal.scss';

const { Option } = Select;

interface AddCareHomeUserModalProps {
    visible: boolean;
    onClose: () => void;
    onSave: (values: FormData) => void;
    user?: CareHomeUser | null;
    isZoneManager: boolean;
    localAuthorities: { id: number, name: string }[];
    isFetching: boolean;
    success: boolean;
}

const AddCareHomeUserModal: React.FC<AddCareHomeUserModalProps> = ({
    visible,
    onClose,
    onSave,
    user,
    isZoneManager,
    localAuthorities = [],
    isFetching,
    success,
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch<AppDispatch>();
    const isViewingZoneAdmin = isZoneManager && user?.role?.name === 'zone_admin';
    const [selectedLocalAuthority, setSelectedLocalAuthority] = useState<number | null>(null);
    const [careHomeInputValue, setCareHomeInputValue] = useState<string>('');
    const [showCreatePrompt, setShowCreatePrompt] = useState<boolean>(false);
    const [isConfirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
    const { careHomes } = useSelector((state: CombinedStates) => state.assessment);

    useEffect(() => {
        if (selectedLocalAuthority) {
            dispatch(fetchCareHomes(selectedLocalAuthority));
        }
    }, [selectedLocalAuthority, dispatch]);

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                name: user.name,
                email: user.email,
                jobTitle: user.job_title,
                role: user.role?.name,
                authority: user.care_home?.local_authority?.id,
                careHome: user.care_home?.title,
            });

            setSelectedLocalAuthority(user.care_home?.local_authority?.id || null);
        } else {
            form.resetFields();
        }
    }, [user, form]);

    useEffect(() => {
        if (success) {
            form.resetFields();
        }
    }, [success, form]);

    const handleOk = () => {
        form.validateFields().then((values) => {
            const careHomeId = careHomes.find((home) => home.title === values.careHome)?.id;
            onSave({
                ...values,
                care_home_id: careHomeId,
                local_authority_id: selectedLocalAuthority,
            });
        }).catch((info) => {
            console.log('Validation Failed:', info);
        });
    };

    const handleLocalAuthorityChange = (value: number) => {
        setSelectedLocalAuthority(value);
        setCareHomeInputValue('');
        setShowCreatePrompt(false);
    };

    const handleCareHomeChange = (value: string) => {
        setCareHomeInputValue(value);
        const matchingCareHomes = careHomes.filter((home) => home.title.toLowerCase().includes(value.toLowerCase()));

        setShowCreatePrompt(matchingCareHomes.length === 0 && value.length > 0);
    };

    const handleCreateCareHome = async () => {
        if (selectedLocalAuthority && careHomeInputValue) {
            try {
                await dispatch(createCareHome({ title: careHomeInputValue, local_authority_id: selectedLocalAuthority }));
                setConfirmModalVisible(false);
                setShowCreatePrompt(false);
                dispatch(fetchCareHomes(selectedLocalAuthority));
            } catch (error) {
                console.error(`Failed to create Care Home: ${error}`);
            }
        }
    };

    const filteredCareHomes = careHomes.filter((home) => home.title.toLowerCase().includes(careHomeInputValue.toLowerCase()));

    return (
        <>
            <Modal
                title={user ? 'View Care Home User' : 'Add Care Home User'}
                open={visible}
                onOk={handleOk}
                onCancel={onClose}
                className="add-care-home-user-modal"
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk} disabled={isFetching || isViewingZoneAdmin}>
                        {isViewingZoneAdmin ? 'View' : 'Save'}
                    </Button>,
                    <Button key="cancel" onClick={onClose} disabled={isFetching}>
                        Cancel
                    </Button>,
                ]}>
                <Spin spinning={isFetching}>
                    <Form form={form} layout="vertical">
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                { required: true, message: 'Please input the name!' },
                            ]}>
                            <Input placeholder="Enter user's name" disabled={isViewingZoneAdmin} />
                        </Form.Item>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[
                                { required: true, message: 'Please input the email address!' },
                                { type: 'email', message: 'Please enter a valid email!' },
                            ]}>
                            <Input placeholder="Enter user's email" disabled={isViewingZoneAdmin} />
                        </Form.Item>
                        {!isZoneManager && (
                            <>
                                <Form.Item label="Local Authority" name="authority" rules={[{ required: true, message: 'Please select an authority!' }]}>
                                    <Select placeholder="Select Authority" onChange={handleLocalAuthorityChange}>
                                        {localAuthorities.map((authority) => (
                                            <Option key={authority.id} value={authority.id}>
                                                {authority.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Care Home Name"
                                    name="careHome"
                                    rules={[
                                        { required: true, message: 'Please input the care home name!' },
                                        { min: 3, message: 'Care home name must be at least 3 characters!' },
                                    ]}>
                                    <AutoComplete
                                        placeholder="Enter care home name"
                                        onSearch={handleCareHomeChange}
                                        options={careHomeInputValue.length >= 3 ? filteredCareHomes.map((home) => ({ value: home.title })) : []}
                                        value={careHomeInputValue}
                                        onChange={setCareHomeInputValue}
                                        allowClear
                                        disabled={isViewingZoneAdmin} />
                                    {showCreatePrompt && (
                                        <div style={{ color: 'red', marginTop: '5px' }}>
                                            Care Home &quot;{careHomeInputValue}&quot; does not exist. <Button type="link" onClick={() => setConfirmModalVisible(true)}>Create New Care Home</Button>
                                        </div>
                                    )}
                                </Form.Item>
                            </>
                        )}
                        <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Please select a role!' }]}>
                            <Select placeholder="Select User Role" disabled={isViewingZoneAdmin}>
                                {!isZoneManager && (
                                    <Option value="zone_admin">Zone Admin</Option>
                                )}
                                <Option value="zone_manager">Manager</Option>
                                <Option value="staff">Care Home Staff</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Job Title" name="jobTitle" rules={[{ required: true, message: 'Please input the job title!' }]}>
                            <Input placeholder="Enter job title" disabled={isViewingZoneAdmin} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Modal
                title="Confirm Care Home Creation"
                open={isConfirmModalVisible}
                onOk={handleCreateCareHome}
                onCancel={() => setConfirmModalVisible(false)}
                okText="Create"
                cancelText="Cancel">
                <p>Care Home &quot;{careHomeInputValue}&quot; does not exist. Do you want to create it?</p>
            </Modal>
        </>
    );
};

export default AddCareHomeUserModal;
