/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedStates, AppDispatch } from 'core/types';
import { Bar } from 'react-chartjs-2';
import { Row, Col, Card, Tooltip } from 'antd';
import { ExclamationIcon } from 'components/Elements/CustomSVGIcon';
import { Roles } from 'core/constants';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip as ChartTooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import './callout.scss';
import { fetchAssessmentCallOuts } from 'core/assessment/assessmentApi';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ChartTooltip,
    Legend,
);

const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                usePointStyle: true,
                font: {
                    weight: 'bold',
                },
            },
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Day',
                font: {
                    size: 15,
                    weight: 'bold',
                },
            },
            ticks: {
                color: '#20639B',
                font: {
                    size: 15,
                },
            },
            grid: {
                display: false,
            },
        },
        y: {
            title: {
                display: true,
                text: 'Total',
                font: {
                    size: 15,
                    weight: 'bold',
                },
            },
            ticks: {
                color: '#20639B',
                font: {
                    size: 15,
                },
            },
            grid: {
                display: true,
            },
        },
    },
};

const colors = ['#6AC17B', '#00AEEF'];

const Callout: React.FC<CalloutProps> = ({ role, careHomeId, timeRange }) => {
    const { data: assessmentData, callOuts } = useSelector((state: CombinedStates) => state.assessment);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (role === Roles.ZONE_MANAGER && careHomeId) {
            dispatch(fetchAssessmentCallOuts({ careHomeId: [careHomeId] }));
        } else {
            dispatch(fetchAssessmentCallOuts({}));
        }
    }, [dispatch, role, careHomeId]);

    const totalAssessments = assessmentData?.['iStumble Fall Guides'] || 0;
    const emergencyServiceCalls = assessmentData?.['Emergency Service Calls'] || 0;
    const nonEmergencyAssessments = useMemo(() => totalAssessments - emergencyServiceCalls, [totalAssessments, emergencyServiceCalls]);
    const moneySaved = useMemo(() => `£${(nonEmergencyAssessments * 350).toLocaleString()}`, [nonEmergencyAssessments]);

    const averageCallOutsPerDay = useMemo(() => {
        if (!callOuts || callOuts.length === 0) return 0;

        const totalCallOuts = callOuts.reduce((sum, item) => sum + item.ambulanceCallOuts, 0);
        return (totalCallOuts / callOuts.length).toFixed(1);
    }, [callOuts]);

    const labels = callOuts ? callOuts.map(item => item.date) : [];

    const maxBarThickness = 50;
    const barThickness = Math.min(maxBarThickness, 600 / labels.length);
    const borderWidth = Math.min(8, barThickness / 10);
    const borderRadius = labels.length <= 7 ? 10 : 5;

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Falls',
                data: callOuts?.map(item => item.falls) || [],
                backgroundColor: colors[0],
                borderColor: 'transparent',
                barThickness,
                borderWidth,
                borderRadius,
            },
            {
                label: 'Ambulance Call Outs',
                data: callOuts?.map(item => item.ambulanceCallOuts) || [],
                backgroundColor: colors[1],
                borderColor: 'transparent',
                barThickness,
                borderWidth,
                borderRadius,
            },
        ],
    };

    return (
        <div className="chart-container">
            <h3 className="title">Fall vs Ambulance Call Outs</h3>
            <div className="chart-wrapper">
                <Bar data={chartData} options={options} />
            </div>
            <Row gutter={16} className="cards-row">
                <Col span={12}>
                    <Card className="custom-card" title="Average Ambulance Call outs">
                        <p className="timeline">{timeRange}</p>
                        <p className="number">{averageCallOutsPerDay}</p>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        className="custom-card"
                        title={(
                            <span>
                                Money Saved by ISTUMBLE{' '}
                                <Tooltip
                                    className="custom-tooltip"
                                    title={(
                                        <span>
                                            Please be aware this is a rough calculation to estimate the cost savings and is not an exact measurement.<br />
                                            <br />
                                            <strong>Calculation:</strong><br />
                                            £350 (cost of ambulance) x Number of ambulances not called in the iStumble process.
                                        </span>
                                    )}
                                    overlayInnerStyle={{ backgroundColor: '#00AEEF', color: 'white', width: '450px', height: '150px', textAlign: 'justify', padding: '10px' }}
                                    mouseEnterDelay={0.1}
                                    mouseLeaveDelay={0.1}
                                    trigger="hover">
                                    <span style={{ position: 'relative' }}>
                                        <ExclamationIcon style={{ marginLeft: 4 }} />
                                    </span>
                                </Tooltip>
                            </span>
                        )}>
                        <p className="timeline">{timeRange}</p>
                        <p className="number">{moneySaved}</p>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Callout;
